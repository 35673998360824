import { useUserStore } from '@/stores/user';
import { useModalsStore } from '@/stores/modals';
import { useAlertsStore } from '@/stores/alerts';
import type { RouteLocationNormalized, RouteLocationRaw, NavigationGuardNext } from 'vue-router';
import i18n from './../translations';
import { useFilterStore } from '@/stores/filter';
import { useSeo } from '@/composables/seo';
import getCategory from '@/api/category';

export function modalInterceptor(to: any, from: any, next: (location?: RouteLocationRaw) => void){
    useModalsStore().toggleModal(to.name);
    next();
}

export function saveModeInterceptor(to: any, from: any, next: (location?: RouteLocationRaw) => void){
    const user = useUserStore();
    if (!user.isLoggedIn()) {
        user.toggleSafeMode();
    }
    next();
}

export async function loggedInInterceptor(to: any, from: any, next: (location?: RouteLocationRaw) => void) {
    const user = useUserStore();
    const modal = useModalsStore();

    if (user.status != 'authenticated') {
        await user.authentication();
    }

    if (!user.isLoggedIn()) {
        modal.toggleModal('login');
        next({ path: '/' });
    } else {
        next();
    }
}

export async function unsubscribeInterceptor(to: any, from: any, next: (location?: RouteLocationRaw) => void) {
    const alert = useAlertsStore();
    const user = useUserStore();
    try {
        await user.unsubscribe(to.params.userId, to.params.token);
        alert.openMessage({
            content: 'confirm.succesMessageUnsubscribe',
            class: 'success'
        });
    } catch(ex) {
        alert.openMessage({
            content: 'confirm.errorUnsubscribe',
            class: 'error'
        });
    }
    next();
}

export async function seoInterceptor(to: any, from: any) {
    const { setCanonical, setTitle, setDescription } = useSeo();
    setCanonical(`${location.protocol}//${location.host}${to.fullPath}`);

    const seoPages = ['grid', 'favourites'];
    if(seoPages.includes(to.name)) {
        const category = to.params.category || 'home';
        const { result, error } = await getCategory(category);

        if(result?.meta_title) { setTitle(result.meta_title) }
        if(result?.meta_description) { setDescription(result.meta_description) }

    } else {
        const titleKey = (to.meta.title) ? `meta.title.${to.name}` : 'meta.title.default';
        const title = i18n.global.t(titleKey);
        setTitle(title.toString());

        // at least populate description even though we don't have it on file
        const descKey = (to.meta.title) ? `meta.desc.${to.name}` : 'meta.title.default';
        const description = i18n.global.t(descKey);
        setDescription(description.toString());
    }
}

export function filterInterceptor(to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) {
    if (to.name === 'grid') {
        const filter = useFilterStore();
        const query: any = filter.getFilterQuery();

        const addServices = !Object.prototype.hasOwnProperty.call(to.query, 'services') && query.services && query.services !== '';
        const addAges = !Object.prototype.hasOwnProperty.call(to.query, 'ages') && query.ages && query.ages !== '';

        if (addServices || addAges) {
            if (!addServices) {
                //remove services from query
                delete to.query.services;
            }

            if (!addAges) {
                //remove ages from query
                delete to.query.ages;
            }
            const updatedNext = {
                ...to,
                query: {
                    // This line is used to retain other query if there is any
                    ...to.query,
                    ...query
                }
            };

            next(updatedNext);
            return;
        }
    }
    next();
}
