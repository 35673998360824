<div class="usermenu" :class="{'usermenu--mobile': mobile}">
    <div class="usermenu__user" v-if="!header">
        <h3>{{account.username.substring(0,26)}}</h3>
        <div class="usermenu__user-credits">
            <span>{{ $t('header.user.currentbalance') }}</span>
            <span>{{account.credits}}</span>
        </div>
    </div>
    <ul class="usermenu__items">
        <li>
            <router-link :to="{name:'editdata'}">
                <i class="fa-solid fa-pencil"></i>
                <span v-if="!small">{{ $t('header.user.edit') }}</span>
            </router-link>
        </li>
        <li v-if="mobile">
            <router-link :to="{name:'changeemail'}">
                <i class="fa-solid fa-at"></i>
                <span v-if="!small">{{ $t('account.changeemail') }}</span>
            </router-link>
        </li>
        <li>
            <router-link :to="{name:'settings'}">
                <i class="fa-solid fa-cog"></i>
                <span v-if="!small">{{ $t('header.user.settings') }}</span>
            </router-link>
        </li>
        <li>
            <router-link :to="{name:'inbox'}">
                <i class="fa-solid fa-envelope"></i>
                <span v-if="!small">{{ $t('header.user.inbox') }}</span>
                <div v-if="chatNotifications > 0 && !small" class="usermenu__items-notifications">
                    {{ chatNotifications > 1 ? '!' : chatNotifications }}
                </div>
            </router-link>
        </li>
        <li>
            <router-link :to="{name:'contentpurchases'}">
                <i class="fa-solid fa-image"></i>
                <span v-if="!small">{{ $t('header.user.contentpurchases') }}</span>
            </router-link>
        </li>
        <li>
            <router-link :to="{name:'news'}">
                <i class="fa-solid fa-newspaper"></i>
                <span v-if="!small">{{ $t('header.user.news') }}</span>
                <div v-if="newsNotifications > 0 && !small" class="usermenu__items-notifications">
                    {{ newsNotifications > 1 ? '!' : newsNotifications }}
                </div>
            </router-link>
        </li>
        <li>
            <router-link :to="{name: 'accounthistory'}">
                <i class="fa-solid fa-clock"></i>
                <span v-if="!small">{{ $t('header.user.history') }}</span>
            </router-link>
        </li>
        <li>
            <router-link :to="{name:'payment'}">
                <i class="fa-solid fa-credit-card"></i>
                <span v-if="!small">{{ $t('header.user.credits') }}</span>
            </router-link>
        </li>
        <!-- <li>
            <router-link :to="{name:'giftvoucher'}">
                <i class="fa-solid fa-gift"></i>
                <span v-if="!small">{{ $t('header.user.giftcard') }}</span>
            </router-link>
        </li> -->
        <li>
            <router-link :to="{name:'help'}">
                <i class="fa-solid fa-circle-question"></i>
                <span v-if="!small">{{ $t('header.user.help') }}</span>
            </router-link>
        </li>
        <li>
            <a href="#" @click="Logout()">
                <i class="fa-solid fa-right-from-bracket"></i>
                <span v-if="!small">{{ $t('header.user.logout') }}</span>
            </a>
        </li>
    </ul>
</div>
