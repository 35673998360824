<div class="catmenu" :class="{mobile: mobile}">
    <div class="catmenu__search" v-if="mobile">
        <Search :mobile="true"></Search>
    </div>
    <ul class="catmenu__mCategories" v-memo="[categories, mobile]" v-if="mobile">
        <li v-for="category in categories" :key="category.slug">
            <router-link :key="category.slug" :to="{ name: 'grid', params: { category: category.slug } }" @click="toggleCatMenu()">{{ category.title }}</router-link>
        </li>
    </ul>
    <div class="catmenu__categories" v-memo="[categories, mobile]" v-if="!mobile">
        <div class="catmenu__categories-links">
            <a href="#" class="loader" v-if="!categories.length"><i class="fas fa-spinner"></i></a>
            <a v-for="category in categories" :key="category.slug" @click.prevent="goCategory(category.slug)" :href="`/${category.slug}`" :alt="category.slug">{{ category.title }}</a>
        </div>
    </div>
</div>