import type { Status as PerformerStatus, Service, Performer, ServiceStatus } from '@/ontology/performer';
import type { Paged, Response } from '../response';
import type { Performer as PerformerFromApi } from './model';

export interface Filter {
    offset: number;
    limit: number;
    search?: string;
    category?: string;
    //Performer to whom to relate other performers.
    //relatedTo refers to an AdvertId, not an ID!
    relatedTo?: number;
    withPhotos?: 0 | 1;
    ages?: string;
}

export interface Performers {
    total: number;
    performerAccounts: PerformerFromApi[];
}

export function transformPerformers(
    response: Response<{
        total: number;
        performerAccounts: PerformerFromApi[];
    }>,
    offset: number
): Response<Paged<Performer>> {
    if (response.error) {
        return { error: response.error };
    }

    const { total, performerAccounts } = response.result!;

    //api response for 'no results' is an empty array ¯\_(ツ)_/¯. To detect that, I check if total has a value.
    //if no, transform to an empty array
    return {
        result: {
            items: total ? performerAccounts.map(transformPerformer) : [],
            offset: offset ?? 0,
            total
        }
    };
}

export function transformPerformer(p: PerformerFromApi): Performer {
    const sharedProperties = ['age', 'avatar', 'country', 'cupSize', 'description', 'eyeColor', 'height', 'id', 'isFavourite', 'isSubscribed', 'isVoyeur', 'language', 'location', 'mediaId', 'nickname', 'status', 'statusUpdated', 'registerDate', 'safeDescription', 'socketToken', 'username', 'weight', 'price', 'photos', 'media', 'publicTags', 'availability'];

    if (!p.advert_numbers) {
        console.log(p);
    }
    const same = filter(p, sharedProperties);
    const different: Partial<Performer> = {
        advertNumber: p.advert_numbers[0].advertNumber,
        avatarMedia: p.avatar_media,
        languages: (p.performerLanguages || 'nl;').split(';').slice(0, -1),
        status: p.performerStatus,
        statusUpdated: p.performerStatusUpdated,
        isBlocked: p.isUnsubscribed,
        services: {
            ...defaultServices(),
            ...Object.entries(p.performer_services).reduce((sofar: any, [service, intention]) => {
                sofar[service] = {
                    status: serviceStatus(p.performerStatus, service as Service, intention),
                    intention
                };
                return sofar;
            }, {})
        },
        role: 'ROLE_PERFORMER',
        safeAvatar: p.safe_avatar
    };

    return {
        ...same,
        ...different
    };
}

export function statuses(services: { [name: string]: { intention: boolean; status: ServiceStatus } }): { [name: string]: ServiceStatus } {
    const result: any = {};
    for (let service in services) {
        result[service] = services[service].status;
    }
    return result;
}

// Wat is de actuele status van een dienst, afhankelijk van de intentie en de status van de performer
// als de intentie niet in de matrix zit, dan is de status van de dienst gelijk aan de intentie.
// Als er 'intentie' is voor een bepaalde dienst, en het resultaat zit niet in de matrix, dan is de status 'available'
export function serviceStatus(performer: PerformerStatus, service: Service, intention: boolean): ServiceStatus {
    //console.log( `wat is de status voor een ${performer} performer en dienst ${service} als de intentie er ${ intention ? 'wel' : 'niet'} is `)
    if (!intention) {
        return 'off';
    }

    // prettier-ignore
    const matrix:any= {
        OFFLINE:  { cam:"off",       vipcam: "off",         videocall: "off",       peek:"off",       voyeur: "off",       toy: "off",       phone: "available" },
        AVAILABLE:{ cam:"available", vipcam: "available",   videocall: "available", peek:"on",        voyeur: "available", toy: "on",        phone: "available" },
        REQUEST:  { cam:"on",        vipcam: "on",          videocall: "on",        peek:"on",        voyeur: "available", toy: "on",        phone: "on" },
        ON_CALL:  { cam:"on",        vipcam: "on",          videocall: "on",        peek:"on",        voyeur: "available", toy: "on",        phone: "on" }, 
        BUSY:     { cam:"on",        vipcam: "on",          videocall: "on",        peek:"available", voyeur: "available", toy: "available", phone: "on" },
        VIPBUSY:  { cam:"on",        vipcam: "on",          videocall: "on",        peek:"off",       voyeur: "off",       toy: "available", phone: "on" } 
    }

    return matrix[performer][service] || 'available';
}

export function defaultServices() {
    const services: Service[] = ['callconfirm', 'cam', 'chat', 'email', 'peek', 'phone', 'sms', 'toy', 'videocall', 'voicemail', 'voyeur', 'vipcam', 'showsnapshot'];

    return services.reduce((sofar: any, current) => {
        sofar[current] = {
            intention: false,
            status: 'off'
        };
        return sofar;
    }, {});
}

//returns a new object containing a (shallow) copy of the properties listed in 'props'
function filter(anObject: any, props: string[]) {
    const result: any = {};
    for (let prop of props) {
        if (prop in anObject) {
            result[prop] = anObject[prop];
        }
    }
    return result;
}

export function getPerformerStatus(performer: Performer): 'offline' | 'available' | 'busy' | 'teaser' {
    switch (performer.status) {
        case 'AVAILABLE':
            if (performer.services.phone.status === 'off' && performer.services.cam.status === 'off' && performer.services.voyeur.status === 'off') {
                return 'offline';
            }

            return 'available';

        case 'OFFLINE':
            if (performer.services.phone.status === 'available') {
                return 'available';
            }

            return 'offline';

        case 'BUSY':
            if(performer.services.voyeur.status !== 'off'){
                return 'teaser';
            }

            return 'busy';

        case 'REQUEST':

        case 'ON_CALL':
            return 'busy';

        default:
            throw new Error('Given status does not exist!');
    }
}

//what is the most relevant service the performer offers that can be consumed?
//TODO: renamve this function? it 'return the most relevant realtime service this performer is available for"
export function camStatus(performer: Performer | undefined): 'unknown' | 'offline' | 'camable' | 'peekable' | 'teasable' | 'callable' | 'busy' {
    if (!performer) {
        return 'unknown';
    }

    if (performer.services['cam'].status == 'available') {
        return 'camable';
    }

    if (performer.status == 'BUSY' && performer.services['peek'].status == 'available') {
        return 'peekable';
    }

    //I'll call a performer peekable if her cam is on (she's in a session) and voyeur is available
    if (['BUSY', 'REQUEST'].includes(performer.status) && performer.services['voyeur'].status == 'available') {
        return 'teasable';
    }

    if (performer.services['phone'].status == 'available') {
        return 'callable';
    }

    if (performer.services['voyeur'].status == 'available') {
        return 'teasable';
    }

    if (getPerformerStatus(performer) == 'busy') {
        return 'busy';
    }

    if (getPerformerStatus(performer) == 'offline') {
        return 'offline';
    }

    throw new Error(`camStatus of ${performer.status} with ${JSON.stringify(performer.services)} is impossible`);
}

//what is the most relevant service/tab the performer offers that can be consumed?
export function tabEnabled(service: any, performer: Performer | undefined) {
    
    if (!performer?.services) {
        return false;
    }

    // If performer is busy and cam or peek are enabled!
    if(performer.status === 'BUSY' && service === 'cam'){
        return performer.services['peek']?.status === 'available';
    }

    // Vipcam
    if(performer.status != 'BUSY' && service === 'vipcam'){
        return performer.services[service]?.status === 'available';
    }

    return performer.services[service]?.status === 'available';
}


export function performerStatusColor(performer: Performer | undefined){
    if (!performer) {
        return 'offline';
    }
    const status = camStatus(performer);
    if(status === 'camable' || status === 'callable'){
        return 'available';
    } else if (status === 'peekable'){
        return performer.status == 'BUSY' ? 'peek' : 'busy';
    } else if (status === 'teasable'){
        return 'teaser';
    } else if (status === 'busy'){
        return 'busy';
    } else {
        return 'offline';
    }
}

//how long ago did we last see the performer? in miliseconds
export function lastSeenAgo(p:Performer):number{
    const seen = p.statusUpdated * 1000;
    const now = Date.now();
    return now - seen;
}

//check if a performer matches a search string
export function match(performer:Performer, search:string){
    search = search.toLowerCase().trim();
    if (!search){
        return true;
    }

    return [ 
        performer.nickname, 
        performer.advertNumber.toString(), 
        performer.location,
        performer.description
    ].some( toMatch => toMatch && toMatch.toLowerCase().includes( search ) );
}
